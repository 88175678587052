<template>
  <div class="not-found-view">
    <p
        class="p404 text-primary"
    >
      404
    </p>

    <h4 class="mb-5">Страница не найдена</h4>

    <b-button
        variant="primary"
        to="/home"
    >
      Перейти на главную
    </b-button>
  </div>
</template>

<script>
export default {
  name: 'NotFound',
};
</script>

<style lang="scss">
.not-found-view {
  padding: 50px 10px;
  text-align: center;

  .p404 {
    font-size: 106px;
  }
}
</style>
